import PropTypes from "prop-types"
import React from "react"
import GridContainer from "../layout/GridContainer/GridContainer"
import Img from "gatsby-image"
import HuntWalkPagination from "../HuntWalkPagination/HuntWalkPagination"

// Import necessary Bulma CSS Framework components.
import "./HuntWalkSecret.library.scss"

// Import component specific styles.
import styles from "./HuntWalkSecret.module.scss"

const HuntWalkSecret = props => (
  <div className={styles.huntWalkSecretWrapper}>
    <div className={styles.backgroundGradient}></div>
    <GridContainer>
      <h1 className={styles.title + " h1Heavy"}>{props.title}</h1>
      <div className="columns">
        <div className="column is-6">
          <p className={styles.topDescription + ' ' + styles.description}>
            {props.description1}
          </p>
          {props.imageSrc && (<Img className={styles.imageMobile} fluid={props.imageSrc} alt={props.imageAlt} title={props.imageTitle} />)}
          <p className={styles.bottomDescription + ' ' + styles.description}>
            {props.description2}
          </p>
          { (props.previousPage || props.nextPage) && (
            <div className={styles.paginationDesktop}>
              <HuntWalkPagination
                nextButton={props.nextButton}
                nextPage={props.nextPage}
                backButton={props.backButton}
                previousPage={props.previousPage}
              />
            </div>
          )}
        </div>
        <div className="column is-6">
          {props.imageSrc && (
            <Img className={styles.imageDesktop} fluid={props.imageSrc} alt={props.imageAlt} title={props.imageTitle} />
          )}

          { (props.previousPage || props.nextPage) && (
            <div className={styles.paginationMobile}>
              <HuntWalkPagination
                nextButton={props.nextButton}
                nextPage={props.nextPage}
                backButton={props.backButton}
                previousPage={props.previousPage}
              />
            </div>
          )}
        </div>
      </div>
    </GridContainer>
  </div>
)

HuntWalkSecret.propTypes = {
  title: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
  imageSrc: PropTypes.object,
  imageAlt: PropTypes.string,
  imageTitle: PropTypes.string,
  nextButton: PropTypes.object,
  nextPage: PropTypes.string,
  previousButton: PropTypes.object,
  previousPage: PropTypes.string,
}

HuntWalkSecret.defaultProps = {
  title: ``,
  description1: ``,
  description2: ``,
  imageSrc: {},
  imageAlt: ``,
  imageTitle: ``,
  nextButton: null,
  nextPage: null,
  previousButton: null,
  previousPage: null,
}

export default HuntWalkSecret
