import React from 'react'
import { graphql } from 'gatsby'
import HuntWalkSecret from '../components/HuntWalkSecret/HuntWalkSecret'
import HuntWalkHeader from '../components/HuntWalkHeader/HuntWalkHeader'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Layout from '../components/layout/Layout/Layout'

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query ($id: String!, $journeyId: String!) {
    datoCmsSelseySecret(id: { eq: $id }) {
      secretName
      topText
      secretImage {
        fluid(maxWidth: 768, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
        title
      }
      bottomText
    }
    datoCmsJourney(id: { eq: $journeyId }) {
      slug
      steps {
        id
      }
    }
  }
`

const SecretPage = (props) => {
  const previousPage = props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 1)

  const nextPage = props.data.datoCmsJourney.steps.length - 1 === props.pageContext.stepNumber ?
    props.data.datoCmsJourney.slug + '/finish' :
    props.data.datoCmsJourney.slug + '/step/' + (props.pageContext.stepNumber + 2)

  return (
    <Layout metaTitle={props.data.datoCmsSelseySecret.secretName} removeNav={true}>
      <HuntWalkHeader secretPage={true} />
      <HuntWalkSecret
        title={props.data.datoCmsSelseySecret.secretName}
        description1={props.data.datoCmsSelseySecret.topText}
        description2={props.data.datoCmsSelseySecret.bottomText}
        imageSrc={props.data.datoCmsSelseySecret.secretImage ? props.data.datoCmsSelseySecret.secretImage.fluid : null}
        imageAlt={props.data.datoCmsSelseySecret.secretImage ? props.data.datoCmsSelseySecret.secretImage.alt : null}
        imageTitle={props.data.datoCmsSelseySecret.secretImage ? props.data.datoCmsSelseySecret.secretImage.title : null}
        previousPage={previousPage}
        backButton={<FaChevronLeft />}
        nextPage={nextPage}
        nextButton={<FaChevronRight />}
      />
    </Layout>
  )
}

export default SecretPage
